import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { images } from "../../../config";

HC_more(Highcharts);
exporting(Highcharts);

export const BpclBarChart = React.memo(({ plantPeakData }) => {
  // console.log("Bpcl bar chart rendered");
  const { selectUnit } = useSelector(
    (state) => state.dateSliceprocessDashboardState
  );
  const chartRef = useRef(null);
  const param = useParams();

  // Prepare data and labels
  const dataSeries = [
    {
      name: "Tag Value",
      data: plantPeakData?.map((item) => parseFloat(item.peak_value)),
      color: "#1C407B",
    },
  ];
  const labels = plantPeakData?.map((item) => item.MIN_KEY);

  const options = {
    chart: {
      type: "column",
      events: {
        load: function () {
          const xAxis = this.xAxis[0];
          const visibleCategories = labels.slice(-5);
          xAxis.setExtremes(
            labels.length - visibleCategories.length,
            labels.length - 1,
            false
          );
          this.redraw();
        },
      },
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: labels,
    },
    yAxis: {
      //   labels: {
      //     enabled: false, // This will hide the Y-axis values
      // },
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: "bold",
          color: "gray",
        },
      },
    },
    plotOptions: {
      series: {
        stacking: "normal",
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: dataSeries,
    scrollbar: {
      enabled: true,
    },
    exporting: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        const pointIndex = this.point.index;
        const currentData = plantPeakData[pointIndex];
        let tooltipContent = `<b>${currentData?.MIN_KEY}</b><br/><br/>Peak Time: ${currentData?.peak_time} <br/> Peak Value : ${currentData?.peak_value} <br/> Tilt Value : ${currentData?.peak_tilt_value}`;

        return tooltipContent;
      },
    },
  };

  useEffect(() => {
    const chart = chartRef.current?.chart;
    if (chart) {
      chart.options.xAxis.min = labels.length - 5;
      chart.options.xAxis.max = labels.length - 1;
      chart.redraw();
    }
  }, [plantPeakData]);

  return (
    <>
      {plantPeakData ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
        />
      ) : (
        <img
          src={images.noDataFoundImg}
          className="notFoundDataImg"
          alt="No Data Found Img"
          style={{ transform: "translate(-50px, 28px)" }}
        />
      )}
    </>
  );
});

// export function BpclBarChart({ plantPeakData }) {
//   console.log("Bpcl bar chart rendered");
//   const { selectUnit } = useSelector(
//     (state) => state.dateSliceprocessDashboardState
//   );
//   const chartRef = useRef(null);
//   const param = useParams();

//   // Prepare data and labels
//   const dataSeries = [
//     {
//       name: "Tag Value",
//       data: plantPeakData?.map((item) => parseFloat(item.tag_value)),
//       color: "#1C407B",
//     },
//   ];
//   const labels = plantPeakData?.map((item) => item.MIN_KEY);

//   const options = {
//     chart: {
//       type: "column",
//       events: {
//         load: function () {
//           const xAxis = this.xAxis[0];
//           const visibleCategories = labels.slice(-5);
//           xAxis.setExtremes(
//             labels.length - visibleCategories.length,
//             labels.length - 1,
//             false
//           );
//           this.redraw();
//         },
//       },
//     },
//     title: {
//       text: null,
//     },
//     legend: {
//       enabled: false,
//     },
//     xAxis: {
//       categories: labels,
//     },
//     yAxis: {
//       //   labels: {
//       //     enabled: false, // This will hide the Y-axis values
//       // },
//       stackLabels: {
//         enabled: false,
//         style: {
//           fontWeight: "bold",
//           color: "gray",
//         },
//       },
//     },
//     plotOptions: {
//       series: {
//         stacking: "normal",
//         dataLabels: {
//           enabled: false,
//         },
//       },
//     },
//     series: dataSeries,
//     scrollbar: {
//       enabled: true,
//     },
//     exporting: {
//       enabled: true,
//     },
//     credits: {
//       enabled: false,
//     },
//     tooltip: {
//       useHTML: true,
//       formatter: function () {
//         const pointIndex = this.point.index;
//         const currentData = plantPeakData[pointIndex];
//         let tooltipContent = `<b>${currentData?.MIN_KEY}</b><br/><br/>Peak Time: ${currentData?.peak_time} <br/> Peak Value : ${currentData?.peak_value} <br/> Peak Tilt : ${currentData?.peak_tilt_value}`;

//         return tooltipContent;
//       },
//     },
//   };

//   useEffect(() => {
//     const chart = chartRef.current?.chart;
//     if (chart) {
//       chart.options.xAxis.min = labels.length - 5;
//       chart.options.xAxis.max = labels.length - 1;
//       chart.redraw();
//     }
//   }, [plantPeakData]);

//   return (
//     <>
//       {plantPeakData ? (
//         <HighchartsReact
//           highcharts={Highcharts}
//           options={options}
//           ref={chartRef}
//         />
//       ) : (
//         <img
//           src={images.noDataFoundImg}
//           className="notFoundDataImg"
//           alt="No Data Found Img"
//         />
//       )}
//     </>
//   );
// }
