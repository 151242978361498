import { request } from "../../../../api/api";

export const getDeviceTagByType = (data) => {
    return request.post('/dashboard/getDeviceTagByType/', data)
}

export const getDeviceConfigData = (params) => {
    return request.post("/dashboard/getDeviceConfig/", params)
}
export const getDeviceConfigDashboard = (params) =>{
    return request.post(`/dashboard/getDeviceConfigByDashboard/`,params)
}

export const getReportParatoChat = (params) => {
    return request.post("/vms/reports/getReportParatoChat/", params)
}

export const getReportLineChart = (params) => {
    return request.post("/vms/reports/getReportLineChat/", params)
}
export const getReportNewLineChart = (params) => {
    return request.post("/smarttag/reports/getSmarTagReportLineChat/", params)
}

export const getReportTableData = (params) => {
    return request.post("/vms/reports/getReportTableData/", params)
}

export const countForVmsPlant = (params) =>{
    return request.post("/vms/reports/countForVMSPlantDashboard/", params)
}

export const plantWiseTitleCount = (params) =>{
    return request.post(`/vms/reports/VMSPlantWiseTitleCount/`,params)
}

export const getUnityModel = (params) => {
    return request.post(`/vms/reports/getUnityModel/`, params)
}

export const  getNotification = (params)=>{
    return request.post(`/vms/getDeviceAlarmStatus/`,params)
}

// Min and Max API
export const geTagAllTimeMinMax = (params) => {
    return request.post(`/vms/reports/getTagTableData/ `, params)
}
// overView
export const selectTagDataApi = (params) => {
    return request.post('/vms/overview/getTagData/', params)
}

export const choiceType = () =>{
    return request.get("/vms/overview/choices/")
}

export const addOverviewTagSuggestionApi = (param) =>{
    return request.post("/vms/overview/addOverviewTagSuggestion/", param)
}

export const getOverviewTagSuggestionApi = (param) =>{
    return request.post("/vms/overview/getOverviewTagSuggestion/", param)
}

export const storeSelectedData = (param) =>{
    return request.post("/vms/storeSelectedData/", param)
}

export const getStorePreviousSelectedData = (param) =>{
    return request.post("/vms/getStorePreviousSelectedData/", param)
}