import React, { useEffect, useMemo, useState } from "react";
import { images } from "../../../config";
import { Box, Grid, Typography } from "@mui/material";
import PlantLocation from "../../../components/BPCL/PlantLocation/PlantLocation";
import "./Analyze.css";

import Mqqtdata from "../../ProcessDashboard/Mqqtdata";
import {
  getHikemmREPlantDashboardCo2Count,
  getHikemmREPlantDashboardCostSavingCount,
  getHikemmREPlantDashboardGenerationCount,
  getHikemmREPlantDashboardTags,
  getHikemmREPlantDashboardUtilizationCount,
} from "../services";
import { BPCLPlant } from "../../CompanyDashboard/services";
import { useNavigate, useParams } from "react-router-dom";
import Datepicker from "../../../components/BPCL/Datepicker/Datepicker";
import { PieChartBPCL } from "../../../components/Graph/BPCL/Analyze-Generation-pie-chart";
import { BarChartBPCL } from "../../../components/Graph/BPCL/Analyze-Generation-Bar-chart";
import { PieChartUtiBPCL } from "../../../components/Graph/BPCL/Analyze-Utilization-Pie-Chart";
import { BarChartUtiBPCL } from "../../../components/Graph/BPCL/Analyze-Utilization-Bar-Chart";
import { PieChartCostBPCL } from "../../../components/Graph/BPCL/Analyze-Cost-Pie-Chart";
import { BarChartCostBPCL } from "../../../components/Graph/BPCL/Analyze-Cost-Bar-Chart";
import { PieChartCo2BPCL } from "../../../components/Graph/BPCL/Analyze-Co2-Pie-Chart";
import { BarChartCo2BPCL } from "../../../components/Graph/BPCL/Analyze-Co2-Bar-Chart";
import { useSelector } from "react-redux";
import {
  CompactTagUnit,
  CompactTagUnitDynamic,
  CompactTagValue,
  CompactTagValueDynamic,
} from "../../../components/CompactValue/CompactValue";

const Analyze = () => {
  const [realTimeData, setRealTimeData] = useState([]);
  const [mqttTagData, setMqttTagData] = useState([]);
  const [topics, setTopics] = useState([]);
  const [realTimePlantDta, setRealTimePlantData] = useState([]);
  const [plantData, setPlantData] = useState([]);
  const [active, setActive] = useState("");
  const [selectedChart, setSelectedChart] = useState("pie");
  const [utiselectedChart, setUtiSelectedChart] = useState("pie");
  const [costSelectedChart, setCostSelectedChart] = useState("pie");
  const [bpclselectedChart, setBpclSelectedChart] = useState("pie");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activeSwitches, setActiveSwitches] = useState([]);
  const [generationCountData, setGenerationCountData] = useState([]);
  const [utilizationCountData, setUtilizationCountData] = useState([]);
  const [costSavingCountData, setCostSavingCountData] = useState([]);
  const [co2CountData, setCo2CountData] = useState([]);
  const [allGenerationCountData, setAllGenerationCountData] = useState([]);
  const [allUtiCountData, setAllUtiCountData] = useState([]);
  const [allCostCountData, setAllCostCountData] = useState([]);
  const [allCo2CountData, setAllCo2CountData] = useState([]);
  const [boxCount, setBoxCount] = useState(0);
  const [displayVslue, setDisplayedValues] = useState([]);
  const [tagUnitSums, setTagUnitSums] = useState({});
  const [previousTagUnitSums, setPreviousTagUnitSums] = useState({});

  const [activeData, setActiveData] = useState([]);

  console.log("costSavingCountData",costSavingCountData)

  const { selectBPCLUnits } = useSelector(
    (state) => state.dateSliceprocessDashboardState
  );

  // console.log("tagUnitSums", tagUnitSums);

  useEffect(() => {
    if (activeSwitches && startDate && endDate) {
      handleGenerationAPI();
      handleUtilizationAPI();
      handleCo2API();
      handleCostSavingAPI();
    }
  }, [startDate, endDate, activeSwitches, selectBPCLUnits]);

  const activePlantIds = activeSwitches.map((switchObj) => switchObj.id);

  const mergedArray = mqttTagData
    ? [].concat(...Object.values(mqttTagData))
    : [];

  const tagValues = realTimeData
    .map((realData) => {
      // Find the matching entry in mergedArray
      const match = mergedArray.find(
        (mergedData) =>
          mergedData.device__uid === realData.device_uid &&
          mergedData.device_tag === realData.device_tag
      );

      // Return the tagValue if a match is found, otherwise return null
      return match ? match.tagValue : null;
    })
    .filter((value) => value !== null); // Filter out null values

  console.log("mergedArray", mergedArray);
  useEffect(() => {
    const updatedValues = realTimePlantDta
      .filter((plant) => activePlantIds.includes(plant.plant_id)) // Filter active plants
      .map((plant) => {
        const matchingData = plant.tags?.reduce((acc, tag) => {
          const tagData = mergedArray.find(
            (item) =>
              item.device__uid === tag.device_uid &&
              item.device_tag === tag.device_tag
          );
          const tagValue = tagData?.tagValue ?? 0;
          const tagName = tag?.tag_unit;
          if (tagName) {
            acc[tagName] = (acc[tagName] || 0) + parseFloat(tagValue); // Accumulate tag values
          }
          return acc;
        }, {});
        return {
          name: plant.plant_name,
          plant_id: plant.plant_id,
          ...matchingData,
        };
      });

    // const activeData = updatedValues.filter(item =>
    //   activeSwitches.some(switchItem => switchItem.id === item.plant_id)
    // );
    const activeData = updatedValues
      .filter((item) =>
        activeSwitches.some((switchItem) => switchItem.id === item.plant_id)
      )
      .map((item) => {
        const matchingSwitch = activeSwitches.find(
          (switchItem) => switchItem.id === item.plant_id
        );
        return {
          ...item,
          color: matchingSwitch?.color || "#9B64B7", // Default color if not found
        };
      });

      console.log("activeDataactiveDataactiveData",activeData)
    // Update the state with the filtered array
    setActiveData(activeData);

    const calculateMetricsAndSet = () => {
      let Gen_mW_Sum = 0;
      let PR_Pct_Sum = 0;
      let MVAr_Sum = 0;
      let CUF_Sum = 0;
      const count = updatedValues.length;

      updatedValues.forEach((plant) => {
        Gen_mW_Sum += parseFloat(plant?.MW) || 0;
        PR_Pct_Sum += parseFloat(plant?.["PR%"]) || 0;
        MVAr_Sum += parseFloat(plant.MVAr) || 0;
        CUF_Sum += parseFloat(plant["CUF%"]) || 0;
      });

      const setValues = {
        MW: Math.round(Gen_mW_Sum),
        MVAr: Math.round(MVAr_Sum / count), // Average
        "PR%": Math.round(PR_Pct_Sum),
        "CUF%": Math.round(CUF_Sum / count), // Average
      };

      setDisplayedValues(setValues); // Update displayed values
      return setValues;
    };

    // Call the function and log results
    const newTagUnitSums = calculateMetricsAndSet();

    setTagUnitSums(newTagUnitSums);
  }, [realTimePlantDta, mqttTagData]);

  // console.log("tagUnitSums", tagUnitSums);

  const handleGenerationAPI = async () => {
    const params = {
      plant_ids: activeSwitches.map((e) => e.id),
      generation_tag_unit: "MW",
      start_date: `${startDate} 00:00:00`,
      end_date: `${endDate} 23:59:59`,
    };
    try {
      const resp = await getHikemmREPlantDashboardGenerationCount(params);
      if (resp.data.success == true) {
        // setGenerationCountData(resp.data.data);
        // setAllGenerationCountData(resp.data);

        // set value baased on the unit of total generation
        const {
          total_generation_value,
          total_generation_value_in_kilo,
          total_generation_value_in_giga,
        } = resp.data;
        switch (selectBPCLUnits) {
          case "m":
            setAllGenerationCountData(total_generation_value);
            break;
          case "k":
            setAllGenerationCountData(total_generation_value_in_kilo);
            break;
          case "g":
            setAllGenerationCountData(total_generation_value_in_giga);
            break;
          default:
            return null;
        }
        // Transform and set generation data array
        const transformedData = resp.data.data.map((item) => {
          let generationValue;
          switch (selectBPCLUnits) {
            case "m":
              generationValue = item.generation_value;
              break;
            case "k":
              generationValue = item.generation_value_in_kilo;
              break;
            case "g":
              generationValue = item.generation_value_in_giga;
              break;
            default:
              generationValue = null;
          }

          return {
            plant_id: item.plant_id,
            plant_name: item.plant_name,
            generation_value: generationValue,
          };
        });

        setGenerationCountData(transformedData); // Set transformed data
      }
    } catch (error) {
      setGenerationCountData([]);
      setAllGenerationCountData([]);
      console.log("handlesubmit", error);
    }
  };

  const handleCostSavingAPI = async () => {
    const params = {
      plant_ids: activeSwitches.map((e) => e.id),
      cost_saving_tag_unit: "MVAr",
      start_date: `${startDate} 00:00:00`,
      end_date: `${endDate} 23:59:59`,
    };
    try {
      const resp = await getHikemmREPlantDashboardCostSavingCount(params);
      if (resp.data.success == true) {
       console.log("sdfdsdsfdsfds",resp.data)
        // setCostSavingCountData(resp.data.data);
        // setAllCostCountData(resp.data);

        const {
          total_cost_saving_value,
          total_cost_saving_value_in_kilo,
          total_cost_saving_value_in_giga,
        } = resp.data;
        switch (selectBPCLUnits) {
          case "m":
            setAllCostCountData(total_cost_saving_value);
            break;
          case "k":
            setAllCostCountData(total_cost_saving_value_in_kilo);
            break;
          case "g":
            setAllCostCountData(total_cost_saving_value_in_giga);
            break;
          default:
            return null;
        }
        // Transform and set generation data array
        const transformedData = resp.data.data.map((item) => {
          let costSavingValue;
          switch (selectBPCLUnits) {
            case "m":
              costSavingValue = item.cost_saving_value;
              break;
            case "k":
              costSavingValue = item.cost_saving_value_in_kilo;
              break;
            case "g":
              costSavingValue = item.cost_saving_value_in_giga;
              break;
            default:
              costSavingValue = null;
          }

          return {
            plant_id: item.plant_id,
            plant_name: item.plant_name,
            cost_saving_value: costSavingValue,
          };
        });

        setCostSavingCountData(transformedData); // Set transformed data
      }
    } catch (error) {
      setCostSavingCountData([]);
      setAllCostCountData([]);
      console.log("handlesubmit", error);
    }
  };

  const handleCo2API = async () => {
    const params = {
      plant_ids: activeSwitches.map((e) => e.id),
      co2_green_tag_unit: "PR%",
      start_date: `${startDate} 00:00:00`,
      end_date: `${endDate} 23:59:59`,
    };
    try {
      const resp = await getHikemmREPlantDashboardCo2Count(params);
      if (resp.data.success == true) {
        // setCo2CountData(resp.data.data);
        // setAllCo2CountData(resp.data);
        setAllCo2CountData(resp.data.total_co2_green_value);
        // Transform and set generation data array
        const transformedData = resp.data.data.map((item) => {
          return {
            plant_id: item.plant_id,
            plant_name: item.plant_name,
            total_co2_green_value: item.co2_green_value,
          };
        });

        setCo2CountData(transformedData); // Set transformed data
      }
    } catch (error) {
      setCo2CountData([]);
      setAllCo2CountData([]);
      console.log("handlesubmit", error);
    }
  };

  const handleUtilizationAPI = async () => {
    const params = {
      plant_ids: activeSwitches.map((e) => e.id),
      utilization_tag_unit: "CUF%",
      start_date: `${startDate} 00:00:00`,
      end_date: `${endDate} 23:59:59`,
    };
    try {
      const resp = await getHikemmREPlantDashboardUtilizationCount(params);
      if (resp.data.success == true) {
        // setUtilizationCountData(resp.data.data);
        // setAllUtiCountData(resp.data);
        setAllUtiCountData(resp.data.total_utilization_value);
        // Transform and set generation data array
        const transformedData = resp.data.data.map((item) => {
          return {
            plant_id: item.plant_id,
            plant_name: item.plant_name,
            utilization_value: item.utilization_value,
          };
        });

        setUtilizationCountData(transformedData); // Set transformed data
      }
    } catch (error) {
      setUtilizationCountData([]);
      setAllUtiCountData([]);
      console.log("handlesubmit", error);
    }
  };

  const params = useParams();

  useEffect(() => {
    getPlantByCompany();
  }, [active]);

  const getPlantByCompany = async () => {
    try {
      const param = {
        dashboard: 1,
        company: params.companyId,
      };

      const resp = await BPCLPlant(param, active);
      if (resp.status == 200) {
        setPlantData(resp.data.payload);
      }
    } catch (error) {
      console.log("Error from getPlantByCompany...", error);
    }
  };
  const plantIds = useMemo(() => plantData.map((e) => e.id), [plantData]);

  useEffect(() => {
    handleRealTimeData();
  }, [plantIds]);

  const handleRealTimeData = async () => {
    const params = {
      plant_ids: plantData.map((e) => e.id),
      generation_tag_unit: "MW",
      utilization_tag_unit: "MVAr",
      cost_saving_tag_unit: "PR%",
      co2_green_tag_unit: "CUF%",
    };
    try {
      const resp = await getHikemmREPlantDashboardTags(params);
      if (resp.status == 200 || resp.status == 201) {
        setRealTimePlantData(resp.data.payload);
      }
    } catch (error) {
      console.log("handlesubmit", error);
    }
  };

  useEffect(() => {
    if (realTimePlantDta.length) {
      const topicArray = realTimePlantDta.flatMap((plant) =>
        plant.tags.map((tag) => "Hikar/DeviceUID/" + tag.device_uid)
      );
      setTopics(topicArray);
    }
  }, [realTimePlantDta]);

  useEffect(() => {
    if (realTimePlantDta.length) {
      const onlyTags = realTimePlantDta.flatMap((plant) => plant.tags);
      setRealTimeData(onlyTags);
    }
  }, [realTimePlantDta]);

  const handleClick = (type) => {
    setActive((prevActive) => (prevActive === type ? null : type));
  };

  const navigate = useNavigate();

  console.log("activeeeee", tagUnitSums?.["CUF%"]);

  const validCountData = Array.isArray(activeData)
    ? activeData.map((item) => ({
        name: item.name,
        value: parseFloat(item.MVAr),
        id: item.plant_id,
      }))
    : [];

  const totalGenerationValue = validCountData.reduce(
    (acc, plant) => acc + (Math.abs(plant.value) || 0),
    0
  );

  const validCountData1 = Array.isArray(activeData)
    ? activeData.map((item) => ({
        name: item.name,
        value: parseFloat(item["CUF%"]),
        id: item.plant_id,
      }))
    : [];

  const totalGenerationValue1 = validCountData1.reduce(
    (acc, plant) => acc + (Math.abs(plant.value) || 0),
    0
  );

  return (
    <>
      <div className="Bpcl-page-wrapper">
        {realTimeData.length ? (
          <Mqqtdata
            setMqttTagData={setMqttTagData}
            topics={topics}
            overViewTagData={realTimeData}
          />
        ) : (
          ""
        )}
        <Grid container>
          <Grid item xs={12} md={3}>
            <PlantLocation
              mqttTagData={mqttTagData}
              realTimePlantDta={realTimePlantDta}
              plantData={plantData}
              active={active}
              handleClick={handleClick}
              activeSwitches={activeSwitches}
              setActiveSwitches={setActiveSwitches}
              setBoxCount={setBoxCount}
              boxCount={boxCount}
              selectBPCLUnits={selectBPCLUnits}
            />
          </Grid>

          <Grid item xs={12} md={9}>
            <Box className="map-box">
              <div className="d-flex justify-content-end gap-2">
                <div className="pt-3">
                  <Datepicker
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                  />
                </div>

                <img
                  alt=""
                  src={images.nextIco}
                  style={{
                    height: "30px",
                    width: "30px",
                    marginTop: "18px",
                    marginRight: "12px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    navigate(
                      `/bpcl-analyze-next-screen/company/${params.companyId}`
                    )
                  }
                />
              </div>
              <div className="generation-data-div">
                <div className="d-flex gap-2">
                  <div>
                    <img src={images.powerImg} alt="" />
                  </div>
                  <div className="gen-value">
                    <h6 style={{ marginBottom: "0px" }}>GENERATION</h6>
                    <h1 class="gen-value-heading">
                      {startDate && endDate
                        ? allGenerationCountData
                        : CompactTagValueDynamic(
                            selectBPCLUnits,
                            tagUnitSums?.MW ? tagUnitSums.MW.toFixed(2) : 0
                          )}

                      <span>
                        {selectBPCLUnits === "m"
                          ? "MW"
                          : selectBPCLUnits === "k"
                          ? "KW"
                          : selectBPCLUnits === "g"
                          ? "GW"
                          : "MW"}
                      </span>
                    </h1>
                  </div>
                </div>
                <div className="gen-div-line"></div>
                <div className="d-flex gap-2">
                  <div>
                    <img
                      src={images.reactivePower}
                      alt=""
                      style={{ width: "63px" }}
                    />
                  </div>
                  <div className="gen-value">
                    <h6 style={{ marginBottom: "0px" }}>REACTIVE POWER</h6>
                    <h1 class="gen-value-heading">
                      {startDate && endDate
                        ? allCostCountData // Display costCount data if dates are present
                        : CompactTagValueDynamic(
                            selectBPCLUnits,
                            totalGenerationValue?.toFixed(2) || 0 // Format totalGenerationValue or default to 0
                          )}

                      <span>
                        {selectBPCLUnits === "m"
                          ? "MVAr"
                          : selectBPCLUnits === "k"
                          ? "KVAr"
                          : selectBPCLUnits === "g"
                          ? "GVAr"
                          : "MVAr"}
                      </span>
                    </h1>
                  </div>
                </div>
                <div className="gen-div-line"></div>
                <div className="d-flex gap-2">
                  <div>
                    <img src={images.utilizationImg} alt="" />
                  </div>
                  <div className="gen-value">
                    <h6 style={{ marginBottom: "0px" }}>PERFORMANCE</h6>
                    <h1 class="gen-value-heading">
                      {startDate && endDate
                        ? allCo2CountData
                        : tagUnitSums?.["PR%"]
                        ? isFinite(tagUnitSums["PR%"] / boxCount)
                          ? (tagUnitSums["PR%"] / boxCount).toFixed(2)
                          : 0
                        : 0}

                      <span>PR%</span>
                    </h1>
                  </div>
                </div>
                <div className="gen-div-line"></div>
                <div className="d-flex gap-2">
                  <div>
                    <img src={images.energyMeterImg} alt="" />
                  </div>
                  <div className="gen-value">
                    <h6 style={{ marginBottom: "0px" }}>UTILIZATION</h6>
                    <h1 class="gen-value-heading">
                      {
                        startDate && endDate
                          ? allUtiCountData // Display total_generation_value if dates are present
                          : isFinite(totalGenerationValue1 / boxCount)
                          ? (totalGenerationValue1 / boxCount).toFixed(2)
                          : 0 // Else safely display the calculated value or fallback to 0
                      }

                      <span>CUF%</span>
                    </h1>
                  </div>
                </div>
              </div>
              <div className="generation-pie-bar-chart-div pt-5">
                <Grid container spacing={2}>
                  <Grid item md={3}>
                    <Box className="barChart-box">
                      <div className="d-flex p-1 justify-content-end">
                        <img
                          src={
                            selectedChart === "pie"
                              ? images.pieChartBgImg
                              : images.pieChart
                          }
                          alt=""
                          className="graph-type-box bpcl-chart-box"
                          style={{ cursor: "pointer" }}
                          onClick={() => setSelectedChart("pie")}
                        />
                        <img
                          src={
                            selectedChart === "bar"
                              ? images.barChartBgImg
                              : images.barChart
                          }
                          alt=""
                          className="graph-type-box bpcl-chart-box"
                          style={{ cursor: "pointer" }}
                          onClick={() => setSelectedChart("bar")}
                        />
                      </div>
                      <div className="pt-3">
                        {activeData.length === 0 ||
                        activeData.every(
                          (item) => parseFloat(item.MW) === 0
                        ) ? (
                          <img
                            src={images.noDataFoundImg}
                            className="notFoundDataImg-analyze"
                            alt="No Data Found Img"
                            style={{marginTop:"38px"}}
                          />
                        ) : selectedChart === "pie" ? (
                          <PieChartBPCL
                            countData={
                              startDate && endDate
                                ? generationCountData.map((item) => ({
                                    name: item.plant_name, // Use plant_name from generationCountData
                                    value: item.generation_value, // Use generation_value based on selectBPCLUnits
                                    id: item.plant_id, // Use plant_id from generationCountData
                                    type: "not-live",
                                  }))
                                : activeData.map((item) => ({
                                    name: item.name,
                                    value: parseFloat(item.MW),
                                    id: item.plant_id,
                                    type: "live",
                                  }))
                            }
                            activeSwitches={activeSwitches}
                            allGenerationCountData={allGenerationCountData}
                          />
                        ) : (
                          <BarChartBPCL
                            countData={
                              startDate && endDate
                                ? generationCountData.map((item) => ({
                                    name: item.plant_name, // Use plant_name from generationCountData
                                    value: parseFloat(item.generation_value), // Use generation_value based on selectBPCLUnits
                                    id: item.plant_id, // Use plant_id from generationCountData
                                    type: "not-live",
                                  }))
                                : activeData.map((item) => ({
                                    name: item.name,
                                    value: parseFloat(item.MW),
                                    id: item.plant_id,
                                    type: "live",
                                  }))
                            }
                            activeSwitches={activeSwitches}
                          />
                        )}
                      </div>
                      <div className="pt-4 chart-title">
                        <p>Generation</p>
                      </div>

                      {/* <hr /> */}
                      <div className="pie-chart-box-data">
                        {startDate && endDate
                          ? generationCountData.map((e) => {
                              const matchingActiveData = activeData.find(
                                (a) => a.plant_id === e.plant_id
                              );
                              const borderColor =
                                matchingActiveData?.color || "#000"; // Fallback color
                              return (
                                <div
                                  className="d-flex justify-content-between pt-1"
                                  style={{
                                    borderLeft: `3px solid ${borderColor}`,
                                    paddingLeft: "4px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <p className="mb-0 truncated">
                                    {e.plant_name}
                                  </p>
                                  <p className="mb-0">
                                    {parseFloat(
                                      e?.generation_value || 0
                                    ).toFixed(2)}
                                    {selectBPCLUnits === "m"
                                      ? "MW"
                                      : selectBPCLUnits === "k"
                                      ? "KW"
                                      : selectBPCLUnits === "g"
                                      ? "GW"
                                      : "MW"}
                                  </p>
                                </div>
                              );
                            })
                          : activeData.length > 0 &&
                            // activeData.some((e) => e.MW > 0) &&
                            activeData.map((e) => {
                              return (
                                <div
                                  className="d-flex justify-content-between pt-1"
                                  style={{
                                    borderLeft: `3px solid ${e.color}`,
                                    paddingLeft: "4px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <p className="mb-0 truncated">{e.name}</p>
                                  <p className="mb-0">
                                    {CompactTagValueDynamic(
                                      selectBPCLUnits,
                                      parseFloat(e?.MW || 0).toFixed(2)
                                    )}
                                    {selectBPCLUnits === "m"
                                      ? "MW"
                                      : selectBPCLUnits === "k"
                                      ? "KW"
                                      : selectBPCLUnits === "g"
                                      ? "GW"
                                      : "MW"}
                                  </p>
                                </div>
                              );
                            })}
                      </div>
                    </Box>
                  </Grid>
                  <Grid item md={3}>
                    <Box className="barChart-box">
                      <div className="d-flex p-1 justify-content-end">
                        <img
                          src={
                            utiselectedChart === "pie"
                              ? images.pieChartBgImg
                              : images.pieChart
                          }
                          alt=""
                          className="graph-type-box bpcl-chart-box"
                          style={{ cursor: "pointer" }}
                          onClick={() => setUtiSelectedChart("pie")}
                        />
                        <img
                          src={
                            utiselectedChart === "bar"
                              ? images.barChartBgImg
                              : images.barChart
                          }
                          alt=""
                          className="graph-type-box bpcl-chart-box"
                          style={{ cursor: "pointer" }}
                          onClick={() => setUtiSelectedChart("bar")}
                        />
                      </div>
                      <div className="pt-3">
                        {activeData.length === 0 ||
                        activeData.every(
                          (item) => parseFloat(item.MVAr) === 0
                        ) ? (
                          <img
                            src={images.noDataFoundImg}
                            className="notFoundDataImg-analyze"
                            alt="No Data Found Img"
                            style={{marginTop:"38px"}}
                          />
                        ) : utiselectedChart === "pie" ? (
                          <PieChartUtiBPCL
                            countData={
                              startDate && endDate
                                ? costSavingCountData.map((item) => ({
                                    name: item.plant_name,
                                    value: item.cost_saving_value,
                                    id: item.plant_id,
                                    type: "not-live",
                                  }))
                                : activeData.map((item) => ({
                                    name: item.name,
                                    value: parseFloat(item.MVAr),
                                    id: item.plant_id,
                                    type: "live",
                                  }))
                            }
                            activeSwitches={activeSwitches}
                            allCostCountData={allCostCountData}
                          />
                        ) : (
                          <BarChartUtiBPCL
                            countData={
                              startDate && endDate
                                ? costSavingCountData.map((item) => ({
                                    name: item.plant_name,
                                    value: item.cost_saving_value,
                                    id: item.plant_id,
                                    type: "not-live",
                                  }))
                                : activeData.map((item) => ({
                                    name: item.name,
                                    value: parseFloat(item.MVAr),
                                    id: item.plant_id,
                                    type: "live",
                                  }))
                            }
                            activeSwitches={activeSwitches}
                          />
                        )}
                      </div>

                      <div className="pt-4 chart-title">
                        <p>Reactive Power</p>
                      </div>
                      {/* <hr /> */}
                      <div className="pie-chart-box-data">
                      {startDate && endDate
                          ? costSavingCountData.map((e) => {
                              const matchingActiveData = activeData.find(
                                (a) => a.plant_id === e.plant_id
                              );
                              const borderColor =
                                matchingActiveData?.color || "#000"; // Fallback color
                              return (
                                <div
                                  className="d-flex justify-content-between pt-1"
                                  style={{
                                    borderLeft: `3px solid ${borderColor}`,
                                    paddingLeft: "4px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <p className="mb-0 truncated">
                                    {e.plant_name}
                                  </p>
                                  <p className="mb-0">
                                    {parseFloat(
                                      e?.cost_saving_value || 0
                                    ).toFixed(2)}
                                    {selectBPCLUnits === "m"
                                      ? "MVAr"
                                      : selectBPCLUnits === "k"
                                      ? "KVAr"
                                      : selectBPCLUnits === "g"
                                      ? "GVAr"
                                      : "MVAr"}
                                  </p>
                                </div>
                              );
                            })
                          : activeData.length > 0 &&
                            // activeData.some((e) => e.MVAr > 0) &&
                            activeData.map((e) => {
                              return (
                                <div
                                  className="d-flex justify-content-between pt-1"
                                  style={{
                                    borderLeft: `3px solid ${e.color}`,
                                    paddingLeft: "4px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <p className="mb-0 truncated">{e.name}</p>
                                  <p className="mb-0">
                                    {CompactTagValueDynamic(
                                      selectBPCLUnits,
                                      parseFloat(e?.MVAr || 0).toFixed(2)
                                    )}
                                    {selectBPCLUnits === "m"
                                      ? "MVAr"
                                      : selectBPCLUnits === "k"
                                      ? "KVAr"
                                      : selectBPCLUnits === "g"
                                      ? "GVAr"
                                      : "MVAr"}
                                  </p>
                                </div>
                              );
                            })}
                      </div>
                    </Box>
                  </Grid>
                  <Grid item md={3}>
                    <Box className="barChart-box">
                      <div className="d-flex p-1 justify-content-end">
                        <img
                          src={
                            costSelectedChart === "pie"
                              ? images.pieChartBgImg
                              : images.pieChart
                          }
                          alt=""
                          className="graph-type-box bpcl-chart-box"
                          style={{ cursor: "pointer" }}
                          onClick={() => setCostSelectedChart("pie")}
                        />
                        <img
                          src={
                            costSelectedChart === "bar"
                              ? images.barChartBgImg
                              : images.barChart
                          }
                          alt=""
                          className="graph-type-box bpcl-chart-box"
                          style={{ cursor: "pointer" }}
                          onClick={() => setCostSelectedChart("bar")}
                        />
                      </div>
                      <div className="pt-3">
                        {activeData.length === 0 ||
                        activeData.every(
                          (item) => parseFloat(item?.["PR%"]) === 0
                        ) ? (
                          <img
                            src={images.noDataFoundImg}
                            className="notFoundDataImg-analyze"
                            alt="No Data Found Img"
                            style={{marginTop:"38px"}}
                          />
                        ) : costSelectedChart === "pie" ? (
                          <PieChartCostBPCL
                            countData={
                              startDate && endDate
                                ? co2CountData.map((item) => ({
                                    name: item.plant_name,
                                    value: item.total_co2_green_value,
                                    id: item.plant_id,
                                    type: "not-live",
                                  }))
                                : activeData.map((item) => ({
                                    name: item.name,
                                    value: parseFloat(item?.["PR%"]),
                                    id: item.plant_id,
                                    type: "live",
                                  }))
                            }
                            activeSwitches={activeSwitches}
                            boxCount={boxCount}
                            dateSelectedData={allCo2CountData}
                          />
                        ) : (
                          <BarChartCostBPCL
                            countData={
                              startDate && endDate
                                ? co2CountData.map((item) => ({
                                    name: item.plant_name,
                                    value: item.total_co2_green_value,
                                    id: item.plant_id,
                                  }))
                                : activeData.map((item) => ({
                                    name: item.name,
                                    value: parseFloat(item?.["PR%"]),
                                    id: item.plant_id,
                                  }))
                            }
                            activeSwitches={activeSwitches}
                          />
                        )}
                      </div>

                      <div className="pt-4 chart-title">
                        <p>Performance</p>
                      </div>
                      {/* <hr /> */}
                      <div className="pie-chart-box-data">
                        {startDate && endDate
                          ? co2CountData.map((e) => {
                              const matchingActiveData = activeData.find(
                                (a) => a.plant_id === e.plant_id
                              );
                              const borderColor =
                                matchingActiveData?.color || "#000"; // Fallback color
                              return (
                                <div
                                  className="d-flex justify-content-between pt-1"
                                  style={{
                                    borderLeft: `3px solid ${borderColor}`,
                                    paddingLeft: "4px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <p className="mb-0 truncated">
                                    {e.plant_name}
                                  </p>
                                  <p className="mb-0">
                                    {parseFloat(
                                      e?.total_co2_green_value || 0
                                    ).toFixed(2)}
                                    PR%
                                  </p>
                                </div>
                              );
                            })
                          : activeData.length > 0 &&
                            // activeData.some((e) => e?.["PR%"] > 0) &&
                            activeData.map((e) => {
                              return (
                                <div
                                  className="d-flex justify-content-between pt-1"
                                  style={{
                                    borderLeft: `3px solid ${e.color}`,
                                    paddingLeft: "4px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <p className="mb-0 truncated">{e.name}</p>
                                  <p className="mb-0">
                                    {parseFloat(e?.["PR%"] || 0).toFixed(2)}
                                    PR%
                                  </p>
                                </div>
                              );
                            })}
                      </div>
                    </Box>
                  </Grid>
                  <Grid item md={3}>
                    <Box className="barChart-box">
                      <div className="d-flex p-1 justify-content-end">
                        <img
                          src={
                            bpclselectedChart === "pie"
                              ? images.pieChartBgImg
                              : images.pieChart
                          }
                          alt=""
                          className="graph-type-box bpcl-chart-box"
                          style={{ cursor: "pointer" }}
                          onClick={() => setBpclSelectedChart("pie")}
                        />
                        <img
                          src={
                            bpclselectedChart === "bar"
                              ? images.barChartBgImg
                              : images.barChart
                          }
                          alt=""
                          className="graph-type-box bpcl-chart-box"
                          style={{ cursor: "pointer" }}
                          onClick={() => setBpclSelectedChart("bar")}
                        />
                      </div>
                      <div className="pt-3">
                        {activeData.length === 0 ||
                        activeData.every(
                          (item) => parseFloat(item["CUF%"]) === 0
                        ) ? (
                          <img
                            src={images.noDataFoundImg}
                            className="notFoundDataImg-analyze"
                            alt="No Data Found Img"
                            style={{marginTop:"38px"}}
                          />
                        ) : bpclselectedChart === "pie" ? (
                          <PieChartCo2BPCL
                            countData={
                              startDate && endDate
                                ? utilizationCountData.map((item) => ({
                                    name: item.plant_name,
                                    value: item.utilization_value,
                                    id: item.plant_id,
                                    type: "not-live",
                                  }))
                                : activeData.map((item) => ({
                                    name: item.name,
                                    value: parseFloat(item["CUF%"]),
                                    id: item.plant_id,
                                    type: "live",
                                  }))
                            }
                            activeSwitches={activeSwitches}
                            boxCount={boxCount}
                            dateSelectedData={allUtiCountData}
                          />
                        ) : (
                          <BarChartCo2BPCL
                            countData={
                              startDate && endDate
                                ? utilizationCountData.map((item) => ({
                                    name: item.plant_name,
                                    value: item.utilization_value,
                                    id: item.plant_id,
                                  }))
                                : activeData.map((item) => ({
                                    name: item.name,
                                    value: parseFloat(item["CUF%"]),
                                    id: item.plant_id,
                                  }))
                            }
                            activeSwitches={activeSwitches}
                          />
                        )}
                      </div>

                      <div className="pt-4 chart-title">
                        <p>Utilization</p>
                      </div>
                      {/* <hr /> */}
                      <div className="pie-chart-box-data">
                        {startDate && endDate
                          ? utilizationCountData.map((e) => {
                              const matchingActiveData = activeData.find(
                                (a) => a.plant_id === e.plant_id
                              );
                              const borderColor =
                                matchingActiveData?.color || "#000"; // Fallback color
                              return (
                                <div
                                  className="d-flex justify-content-between pt-1"
                                  style={{
                                    borderLeft: `3px solid ${borderColor}`,
                                    paddingLeft: "4px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <p className="mb-0 truncated">
                                    {e.plant_name}
                                  </p>
                                  <p className="mb-0">
                                    {parseFloat(
                                      e?.utilization_value || 0
                                    ).toFixed(2)}
                                    CUF%
                                  </p>
                                </div>
                              );
                            })
                          : activeData.length > 0 &&
                            // activeData.some((e) => e["CUF%"] > 0) &&
                            activeData.map((e) => {
                              return (
                                <div
                                  className="d-flex justify-content-between pt-1"
                                  style={{
                                    borderLeft: `3px solid ${e.color}`,
                                    paddingLeft: "4px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <p className="mb-0 truncated">{e.name}</p>
                                  <p className="mb-0">
                                    {parseFloat(e?.["CUF%"] || 0).toFixed(2)}
                                    CUF%
                                  </p>
                                </div>
                              );
                            })}
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Analyze;
