import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { Unity, useUnityContext } from "react-unity-webgl";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderDetails } from "../../../../Header/headerSlice";

function VmsARMachine({ deviceUID, navigateRoute, modelUrlData, setUnitySelectTag, fullScreenModel, setFullScreenModel, sendRealTimeDataModel }) {
  const [unityProviderState, setUnityProviderState] = useState(null);
  const [uid, setSendDataToUnity] = useState("");
  const navigate = useNavigate();
  
  // Ref for full-screen element
  const fullScreenRef = useRef(null); 

  console.log("fullScreenRef",fullScreenRef)

  const { unityProvider, unload, isLoaded, sendMessage, addEventListener, removeEventListener } =
    new useUnityContext({
      loaderUrl: `https://hikemm.s3.ap-south-1.amazonaws.com/unity_builds/hikar_addressable/PredHik_unity/Build/PredHik_unity.loader.js`,
      dataUrl: `https://hikemm.s3.ap-south-1.amazonaws.com/unity_builds/hikar_addressable/PredHik_unity/Build/PredHik_unity.data`,
      frameworkUrl: `https://hikemm.s3.ap-south-1.amazonaws.com/unity_builds/hikar_addressable/PredHik_unity/Build/PredHik_unity.framework.js`,
      codeUrl: `https://hikemm.s3.ap-south-1.amazonaws.com/unity_builds/hikar_addressable/PredHik_unity/Build/PredHik_unity.wasm`,
      streamingAssetsUrl: `https://hikemm.s3.ap-south-1.amazonaws.com/unity_builds/hikar_addressable/PredHik_unity/StreamingAssets`,
    });

    const dispatch = useDispatch()
  const { isFullscreen } = useSelector((state) => state.headerState);

  const handleUnityMessage = useCallback((message) => {
    if (setUnitySelectTag) {
      setUnitySelectTag(message);
    }
  }, []);

  useEffect(() => {
    addEventListener("MessageFromUnity", handleUnityMessage);
    return () => {
      removeEventListener("MessageFromUnity", handleUnityMessage);
    };
  }, [addEventListener, removeEventListener, handleUnityMessage]);

  function handleSelectedModel() {
    sendMessage("AppHandler", "LoadModel", deviceUID.replace(/_/g, '/'));
  }

  const handleSendDataToUnity = useCallback((uid) => {
    setSendDataToUnity(uid);
  }, []);

  useEffect(() => {
    addEventListener("SendDataToUnity", handleSendDataToUnity);
    return () => {
      removeEventListener("SendDataToUnity", handleSendDataToUnity);
    };
  }, [addEventListener, removeEventListener, handleSendDataToUnity]);

  useEffect(() => {
    if (isLoaded) {
      handleSelectedModel();
    }
  }, [isLoaded]);

  useEffect(() => {
    try {
      setUnityProviderState(unityProvider);
    } catch (error) {
      console.error('An error occurred:', error.message);
    }
  }, [deviceUID]);

  const handleNavigateUnload = async () => {
    if (navigateRoute) {
      navigate(navigateRoute);
    }
  }

  useEffect(() => {
    handleNavigateUnload();
    return async () => {
      isLoaded && await unload();
    }
  }, [navigateRoute]);

  // Handle fullscreen
  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement && fullScreenModel) {
        setFullScreenModel(false);
        dispatch(setHeaderDetails({isFullscreen: false}));
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    if (fullScreenModel && fullScreenRef.current) {
      fullScreenRef.current.requestFullscreen().catch(err => {
        setFullScreenModel(false);
        dispatch(setHeaderDetails({isFullscreen: false}));
      });
    } else if (!fullScreenModel && document.fullscreenElement) {
      document.exitFullscreen().catch(err => {
        setFullScreenModel(true);
        dispatch(setHeaderDetails({isFullscreen: false}));
      });
    }

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [fullScreenModel,isFullscreen]);

  // Live data update model
  function SendSelectedModelData(data) {
    sendMessage("DataManager", "DataReceiver", data);
  }

  useEffect(() => {
    if (sendRealTimeDataModel.length) {
      SendSelectedModelData(JSON.stringify(sendRealTimeDataModel));
    }
  }, [sendRealTimeDataModel]);
  console.log("unityProviderState",unityProviderState)

  return (
    <>
      <div>
        {!isLoaded && <div className="dots-spinner"></div>}
      </div>
      <div ref={fullScreenRef} style={{ width: "80%", height: "100%", cursor: "pointer",marginLeft:"auto", marginRight:"auto" }}>
        {unityProviderState ? <Unity
          unityProvider={unityProviderState}
          style={{ width: "100%", height: "100%" }}
        /> : null}
      </div>
    </>
  );
}

export default VmsARMachine;