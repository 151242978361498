import React, { useState } from "react";
import {
  Grid,
  Card,
  Typography,
  Box,
  Select,
  MenuItem,
  Switch,
  FormControl,
} from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Button } from "bootstrap";
import "../../../components/BPCL/BPCLInverters/BPCLInverters.css";
import { InverterBarChart } from "../../Graph/BPCL/Inverter-bar-chart";
import { InverterDonutChart } from "../../Graph/BPCL/Inverter-pie-chart";
import { SoftTagFormula } from "../../../container/SmartTag/softTag/SoftTagFormula";
import { getLabels } from "../BPCL_labels";
import Datepicker2 from "../Datepicker/Datepicker2";
import Datepicker from "../Datepicker/Datepicker";

const BPCLInverters = (props) => {
  const [showInverterAverage, setShowInverterAverage] = useState(false);

  // Column chart options
  const chartOptions = {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: [
        "INV01",
        "INV02",
        "INV03",
        "INV04",
        "INV05",
        "INV06",
        "INV07",
        "INV08",
        "INV09",
      ],
      title: {
        text: "Inverters",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "kWh",
      },
    },

    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Inverter Output",
        data: [4000, 10000, 9000, 10000, 3000, 7000, 10000, 8000, 6000],
        color: "#9C27B0",
      },
    ],
  };

  // Donut chart options
  const donutChartOptions = {
    chart: {
      type: "pie",
    },
    title: {
      text: "65%",
      align: "center",
      verticalAlign: "middle",
      y: 20,
      style: {
        fontSize: "24px",
        fontWeight: "bold",
        color: "#000",
      },
    },
    plotOptions: {
      pie: {
        innerSize: "70%",
        dataLabels: {
          enabled: false,
        },
      },
    },

    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Energy Generated",
        data: [
          { name: "Generated", y: 4800, color: "#9C27B0" },
          { name: "Remaining", y: 5200, color: "#E0E0E0" },
          { name: "Energy", y: 5100, color: "#A9B0BD" },
        ],
      },
    ],
  };
  const mqttDataReduce = props.sliderRealTimeTagData.reduce((map, item) => {
    map[item.device_tag] = Number(item.tagValue);
    return map;
  }, {});
  const tag = 0;
  const tag1 = 0; // Using the first item or modify as needed
  const tag2 = props.weatherGraphData[0];
  const tag3 = props.weatherGraphData[1];
  const tag4 = props.weatherGraphData[2];
  const tag5 = props.weatherGraphData[3];
  const tag6 = props.weatherGraphData[4];
  const tag7 = props.weatherGraphData[5];
  const tag8 = props.weatherGraphData[6];

  let tagValue = props.mergedArray.length
    ? props.mergedArray.find(
        (f) =>
          f.device__uid === tag.device_uid && f.device_tag === tag.device_tag
      )?.["tagValue"] || 0.0
    : 0.0;

  if (
    tag?.is_soft_tag &&
    tag?.soft_tag_id__formula &&
    Object.keys(mqttDataReduce).length
  ) {
    const softTagFormulaResult = SoftTagFormula(
      tag.soft_tag_id__formula,
      mqttDataReduce
    );
    if (softTagFormulaResult?.softTagNotGetTag) {
      tagValue = softTagFormulaResult.value;
    }
  }

  let tagValue1 = props.mergedArray.length
    ? props.mergedArray.find(
        (f) =>
          f.device__uid === tag1.device_uid && f.device_tag === tag1.device_tag
      )?.["tagValue"] || 0.0
    : 0.0;

  if (
    tag1?.is_soft_tag &&
    tag1?.soft_tag_id__formula &&
    Object.keys(mqttDataReduce).length
  ) {
    const softTagFormulaResult = SoftTagFormula(
      tag1.soft_tag_id__formula,
      mqttDataReduce
    );
    if (softTagFormulaResult?.softTagNotGetTag) {
      tagValue = softTagFormulaResult.value;
    }
  }

  let tagValue2 = props.mergedArray.length
    ? props.mergedArray.find(
        (f) =>
          f.device__uid === tag2.device_uid && f.device_tag === tag2.device_tag
      )?.["tagValue"] || 0.0
    : 0.0;

  if (
    tag2?.is_soft_tag &&
    tag2?.soft_tag_id__formula &&
    Object.keys(mqttDataReduce).length
  ) {
    const softTagFormulaResult = SoftTagFormula(
      tag2.soft_tag_id__formula,
      mqttDataReduce
    );
    if (softTagFormulaResult?.softTagNotGetTag) {
      tagValue = softTagFormulaResult.value;
    }
  }

  let tagValue3 = props.mergedArray.length
    ? props.mergedArray.find(
        (f) =>
          f.device__uid === tag3.device_uid && f.device_tag === tag3.device_tag
      )?.["tagValue"] || 0.0
    : 0.0;

  if (
    tag3?.is_soft_tag &&
    tag3?.soft_tag_id__formula &&
    Object.keys(mqttDataReduce).length
  ) {
    const softTagFormulaResult = SoftTagFormula(
      tag3.soft_tag_id__formula,
      mqttDataReduce
    );
    if (softTagFormulaResult?.softTagNotGetTag) {
      tagValue = softTagFormulaResult.value;
    }
  }

  let tagValue4 = props.mergedArray.length
    ? props.mergedArray.find(
        (f) =>
          f.device__uid === tag4.device_uid && f.device_tag === tag4.device_tag
      )?.["tagValue"] || 0.0
    : 0.0;

  if (
    tag4?.is_soft_tag &&
    tag4?.soft_tag_id__formula &&
    Object.keys(mqttDataReduce).length
  ) {
    const softTagFormulaResult = SoftTagFormula(
      tag4.soft_tag_id__formula,
      mqttDataReduce
    );
    if (softTagFormulaResult?.softTagNotGetTag) {
      tagValue = softTagFormulaResult.value;
    }
  }

  let tagValue5 = props.mergedArray.length
    ? props.mergedArray.find(
        (f) =>
          f.device__uid === tag5.device_uid && f.device_tag === tag5.device_tag
      )?.["tagValue"] || 0.0
    : 0.0;

  if (
    tag5?.is_soft_tag &&
    tag5?.soft_tag_id__formula &&
    Object.keys(mqttDataReduce).length
  ) {
    const softTagFormulaResult = SoftTagFormula(
      tag5.soft_tag_id__formula,
      mqttDataReduce
    );
    if (softTagFormulaResult?.softTagNotGetTag) {
      tagValue = softTagFormulaResult.value;
    }
  }

  let tagValue6 = props.mergedArray.length
    ? props.mergedArray.find(
        (f) =>
          f.device__uid === tag6.device_uid && f.device_tag === tag6.device_tag
      )?.["tagValue"] || 0.0
    : 0.0;

  if (
    tag6?.is_soft_tag &&
    tag6?.soft_tag_id__formula &&
    Object.keys(mqttDataReduce).length
  ) {
    const softTagFormulaResult = SoftTagFormula(
      tag6.soft_tag_id__formula,
      mqttDataReduce
    );
    if (softTagFormulaResult?.softTagNotGetTag) {
      tagValue = softTagFormulaResult.value;
    }
  }

  let tagValue7 = props.mergedArray.length
    ? props.mergedArray.find(
        (f) =>
          f.device__uid === tag7.device_uid && f.device_tag === tag7.device_tag
      )?.["tagValue"] || 0.0
    : 0.0;

  if (
    tag7?.is_soft_tag &&
    tag7?.soft_tag_id__formula &&
    Object.keys(mqttDataReduce).length
  ) {
    const softTagFormulaResult = SoftTagFormula(
      tag7.soft_tag_id__formula,
      mqttDataReduce
    );
    if (softTagFormulaResult?.softTagNotGetTag) {
      tagValue = softTagFormulaResult.value;
    }
  }

  let tagValue8 = props.mergedArray.length
    ? props.mergedArray.find(
        (f) =>
          f.device__uid === tag8.device_uid && f.device_tag === tag8.device_tag
      )?.["tagValue"] || 0.0
    : 0.0;

  if (
    tag8?.is_soft_tag &&
    tag8?.soft_tag_id__formula &&
    Object.keys(mqttDataReduce).length
  ) {
    const softTagFormulaResult = SoftTagFormula(
      tag8.soft_tag_id__formula,
      mqttDataReduce
    );
    if (softTagFormulaResult?.softTagNotGetTag) {
      tagValue = softTagFormulaResult.value;
    }
  }

  const findStatus = props.tagStatus1.length
    ? props.tagStatus1.find((f) => f.dvName == tag?.device_tag)
    : 0;

  const formattedTagValue =
    typeof tagValue === "number" &&
    tagValue % 1 !== 0 &&
    tagValue !== 0 &&
    tagValue !== 1
      ? tagValue.toFixed(5)
      : tagValue || tagValue;

  const formattedTagValue1 =
    typeof tagValue === "number" &&
    tagValue1 % 1 !== 0 &&
    tagValue1 !== 0 &&
    tagValue1 !== 1
      ? tagValue1.toFixed(5)
      : tagValue1 || tagValue1;

  const formattedTagValue2 =
    typeof tagValue2 === "number" &&
    tagValue2 % 1 !== 0 &&
    tagValue2 !== 0 &&
    tagValue2 !== 1
      ? tagValue2.toFixed(5)
      : tagValue2 || tagValue2;

  const formattedTagValue3 =
    typeof tagValue3 === "number" &&
    tagValue3 % 1 !== 0 &&
    tagValue3 !== 0 &&
    tagValue3 !== 1
      ? tagValue3.toFixed(5)
      : tagValue3 || tagValue3;

  const formattedTagValue4 =
    typeof tagValue3 === "number" &&
    tagValue4 % 1 !== 0 &&
    tagValue4 !== 0 &&
    tagValue4 !== 1
      ? tagValue4.toFixed(5)
      : tagValue4 || tagValue4;

  const formattedTagValue5 =
    typeof tagValue5 === "number" &&
    tagValue5 % 1 !== 0 &&
    tagValue5 !== 0 &&
    tagValue5 !== 1
      ? tagValue5.toFixed(5)
      : tagValue5 || tagValue5;

  const formattedTagValue6 =
    typeof tagValue6 === "number" &&
    tagValue6 % 1 !== 0 &&
    tagValue6 !== 0 &&
    tagValue6 !== 1
      ? tagValue6.toFixed(5)
      : tagValue6 || tagValue6;

  const formattedTagValue7 =
    typeof tagValue7 === "number" &&
    tagValue7 % 1 !== 0 &&
    tagValue7 !== 0 &&
    tagValue7 !== 1
      ? tagValue7.toFixed(5)
      : tagValue7 || tagValue7;

  const formattedTagValue8 =
    typeof tagValue8 === "number" &&
    tagValue8 % 1 !== 0 &&
    tagValue8 !== 0 &&
    tagValue8 !== 1
      ? tagValue8.toFixed(5)
      : tagValue8 || tagValue8;

  const labels = getLabels(props.inverterTime);

  console.log("props.inverterData", props.inverterData);

  return (
    <Box sx={{ padding: 3 }} className="inverters-box availability-box">
      <Grid container spacing={3}>
        {/* Left Side (Chart and Selectors) */}
        <Grid item xs={12} md={12}>
          {/* Chart Title and Selectors */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 2,
              justifyContent: "space-between",
            }}
          >
            <div className="d-flex gap-2">
              <Typography
                variant="h5"
                className="typography_Growth"
                style={{ fontWeight: "600", marginRight: "40px" }}
              >
                Inverters
              </Typography>
              <div className="average_buttons">
                <h6 className="average">Average</h6>
                <Switch
                  className="averagebuttons"
                  checked={showInverterAverage}
                  onChange={(e) => setShowInverterAverage(e.target.checked)}
                />
                <b>
                  {showInverterAverage
                    ? props.inverterData?.total_average_value
                    : ""}
                </b>
                <h6 className="average-rate">
                  {showInverterAverage ? props.inverterData?.tag_unit : ""}
                </h6>
              </div>
              <Datepicker
                startDate={props.inverterStartDate}
                endDate={props.inverterEndDate}
                setStartDate={props.setInverterStartDate}
                setEndDate={props.setInverterEndDate}
              />
            </div>
            {/* <Typography variant="h6">AGG</Typography> */}
            <Box
              sx={{
                display: "flex",
                gap: 2,
              }}
            >
              <Select
                value={props.inverterTime}
                onChange={props.handleDateInverterTime}
                size="small"
                className="BPCL_inveters"
                // sx={{ padding: "1.7rem 0" }}
              >
                <MenuItem value={1}>Today</MenuItem>
                <MenuItem value={2}>Week</MenuItem>
                <MenuItem value={3}>Month</MenuItem>
                <MenuItem value={4}>Quarter</MenuItem>
                <MenuItem value={5}>Year</MenuItem>
              </Select>
            </Box>
          </Box>
        </Grid>

        {/* Right Side (Summary Boxes with Donut Chart) */}
        <Grid container spacing={2}>
          {/* Main Chart */}
          <Grid item xs={12} md={6} className="grid-vertical-item">
            <InverterBarChart
              inverterData={
                props.inverterStartDate && props.inverterEndDate
                  ? props.inverterDateWiseData
                  : props.inverterData
              }
              showInverterAverage={showInverterAverage}
              startDate={props.inverterStartDate}
              endDate={props.inverterEndDate}
            />
            {/* <HighchartsReact highcharts={Highcharts} options={chartOptions} /> */}
          </Grid>

          {/* Secondary Donut Chart */}
          <Grid item xs={12} sm={12} md={3} className="grid-vertical-item">
            <InverterDonutChart
              inverterData={props.inverterData}
              labels={labels}
            />
          </Grid>

          {/* Metrics Section */}
          <Grid item xs={12} sm={12} md={3}>
            <h6 className="mt-4 invertersgenration">Inverter Generation</h6>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <button className="BPCLavailability1">
                  <h3 className="bpclPlant labelOne" style={{ color: "black" }}>
                    {labels.labelOne}
                  </h3>
                  <div className="d-flex  flex-availbility">
                    <h3 className="plant_time" style={{ color: "black" }}>
                      {props.inverterData.current_total}
                    </h3>
                    <span className="plant_hrs" style={{ color: "black" }}>
                      {props.inverterData.tag_unit}
                    </span>
                  </div>
                </button>
              </Grid>

              <Grid item xs={12} md={6}>
                <button className="BPCLavailability1">
                  <h3 className="bpclPlant labelTwo" style={{ color: "black" }}>
                    {labels.labelTwo}
                  </h3>
                  <div className="d-flex flex-availbility">
                    <h3 className="plant_time" style={{ color: "black" }}>
                      {props.inverterData.past_total}
                    </h3>
                    <span className="plant_hrs" style={{ color: "black" }}>
                      {props.inverterData.tag_unit}
                    </span>
                  </div>
                </button>
              </Grid>
            </Grid>

            <h6 className="mt-4">Inverter Generation - Min</h6>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <button className="BPCLavailability1">
                  <h3 className="bpclPlant" style={{ color: "black" }}>
                    Min
                  </h3>
                  <div className="d-flex flex-availbility">
                    <h3 className="plant_time" style={{ color: "black" }}>
                      {props.inverterData?.current_min?.value}
                    </h3>
                    <span className="plant_hrs" style={{ color: "black" }}>
                      {props.inverterData?.current_min?.tag_unit}
                    </span>
                  </div>
                </button>
              </Grid>

              <Grid item xs={12} md={6}>
                <button className="BPCLavailability1">
                  <h3 className="bpclPlant" style={{ color: "black" }}>
                    Inverter
                  </h3>
                  <div className="d-flex flex-availbility">
                    <h3 className="plant_time" style={{ color: "black" }}>
                      {props.inverterData?.current_min?.device_name}
                    </h3>
                    {/* <span className="plant_hrs" style={{ color: "black" }}>
                      {props.inverterData?.current_min?.tag_unit}
                    </span> */}
                  </div>
                </button>
              </Grid>
            </Grid>

            <h6 className="mt-4">Inverter Generation - Max</h6>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <button className="BPCLavailability1">
                  <h3 className="bpclPlant" style={{ color: "black" }}>
                    Max
                  </h3>
                  <div className="d-flex flex-availbility">
                    <h3 className="plant_time" style={{ color: "black" }}>
                      {props.inverterData?.current_max?.value}
                    </h3>
                    <span className="plant_hrs" style={{ color: "black" }}>
                      {props.inverterData?.current_max?.tag_unit}
                    </span>
                  </div>
                </button>
              </Grid>

              <Grid item xs={12} md={6}>
                <button className="BPCLavailability1">
                  <h3 className="bpclPlant" style={{ color: "black" }}>
                    Inverter
                  </h3>
                  <div className="d-flex flex-availbility">
                    <h3 className="plant_time" style={{ color: "black" }}>
                      {props.inverterData?.current_max?.device_name}
                    </h3>
                    {/* <span className="plant_hrs" style={{ color: "black" }}>
                      {props.inverterData?.current_max?.tag_unit}
                    </span> */}
                  </div>
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={3} className="grid-item-vertical">
            <h5 style={{ fontWeight: "bold" }}>Inverters</h5>
            <div className="pt-1 d-flex gap-2">
              <button className="BPCLavailability1">
                <h3 className="bpclPlant" style={{ color: "black" }}>
                  Total
                </h3>
                <div className="d-flex flex-availbility">
                  <h3 className="plant_time" style={{ color: "black" }}>
                    4
                  </h3>
                </div>
              </button>
              <button className="BPCLavailability1">
                <h3 className="bpclPlant" style={{ color: "black" }}>
                  Operational
                </h3>
                <div className="d-flex flex-availbility">
                  <h3 className="plant_time" style={{ color: "black" }}>
                    4
                  </h3>
                </div>
              </button>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} className="grid-item-vertical">
            <h5 style={{ fontWeight: "bold" }}>Grid Parameters</h5>
            <div className="pt-1 d-flex gap-2">
              <button className="BPCLavailability1">
                <h3 className="bpclPlant" style={{ color: "black" }}>
                  Voltage
                </h3>
                <div className="d-flex flex-availbility">
                  <h3 className="plant_time" style={{ color: "black" }}>
                    {formattedTagValue2}
                  </h3>
                  <span className="plant_hrs" style={{ color: "black" }}>
                    {tag2?.tag_unit}
                  </span>
                </div>
              </button>
              <button className="BPCLavailability1">
                <h3 className="bpclPlant" style={{ color: "black" }}>
                  Frequency
                </h3>
                <div className="d-flex flex-availbility">
                  <h3 className="plant_time" style={{ color: "black" }}>
                    {formattedTagValue3}
                  </h3>
                  <span className="plant_hrs" style={{ color: "black" }}>
                    {tag3?.tag_unit}
                  </span>
                </div>
              </button>
              <button className="BPCLavailability1">
                <h3 className="bpclPlant" style={{ color: "black" }}>
                  Current
                </h3>
                <div className="d-flex flex-availbility">
                  <h3 className="plant_time" style={{ color: "black" }}>
                    {formattedTagValue4}
                  </h3>
                  <span className="plant_hrs" style={{ color: "black" }}>
                    {tag4?.tag_unit}
                  </span>
                </div>
              </button>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <h5 style={{ fontWeight: "bold" }}>Transformer Parameters</h5>
            <div className="pt-1 d-flex gap-2">
              <button className="BPCLavailability1">
                <h3 className="bpclPlant" style={{ color: "black" }}>
                  HT
                </h3>
                <div className="d-flex flex-availbility">
                  <h3 className="plant_time" style={{ color: "black" }}>
                    {formattedTagValue5}
                  </h3>
                  <span className="plant_hrs" style={{ color: "black" }}>
                    {tag5?.tag_unit}
                  </span>
                </div>
              </button>
              <button className="BPCLavailability1">
                <h3 className="bpclPlant" style={{ color: "black" }}>
                  LT
                </h3>
                <div className="d-flex flex-availbility">
                  <h3 className="plant_time" style={{ color: "black" }}>
                    {formattedTagValue6}
                  </h3>
                  <span className="plant_hrs" style={{ color: "black" }}>
                    {tag6?.tag_unit}
                  </span>
                </div>
              </button>
              <button className="BPCLavailability1">
                <h3 className="bpclPlant" style={{ color: "black" }}>
                  OTI
                </h3>
                <div className="d-flex flex-availbility">
                  <h3 className="plant_time" style={{ color: "black" }}>
                    {formattedTagValue7}
                  </h3>
                  <span className="plant_hrs" style={{ color: "black" }}>
                    {tag7?.tag_unit}
                  </span>
                </div>
              </button>
              <button className="BPCLavailability1">
                <h3 className="bpclPlant" style={{ color: "black" }}>
                  WTI
                </h3>
                <div className="d-flex flex-availbility">
                  <h3 className="plant_time" style={{ color: "black" }}>
                    {formattedTagValue8}
                  </h3>
                  <span className="plant_hrs" style={{ color: "black" }}>
                    {tag8?.tag_unit}
                  </span>
                </div>
              </button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BPCLInverters;
