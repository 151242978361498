import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import { useSelector } from "react-redux";
import { images } from "../../../config";
import dayjs from "dayjs";

HC_more(Highcharts);
exporting(Highcharts);

export const MultiAxisLineChart = React.memo(
  ({
    sliderRealTimeTagData,
    realTimeData,
    RealTimeData,
    RealTimeData1,
    chartLineData,
    combineChartData,
    selectTimeRange,
    setCombineChartData,
  }) => {
    const chartRef = useRef(null);
    const [dataSeries1, setDataSeries1] = useState([]);
    const [dataSeries2, setDataSeries2] = useState([]);
    const [xCategories, setXCategories] = useState([]);

    useEffect(() => {
      setCombineChartData([[], []]);
    }, [selectTimeRange]);
    
    console.log("RealTimeData",RealTimeData)

    useEffect(() => {
      if (selectTimeRange === "live") {
        if (sliderRealTimeTagData.length > 1) {
          const newPoint1 = parseFloat(RealTimeData?.tagValue) || 0;
          const newPoint2 = parseFloat(RealTimeData1?.tagValue) || 0;
          const newTimestamp =
            RealTimeData?.tagDateAndTime.split(", ")[1] || "";

          if (!xCategories.includes(newTimestamp)) {
            setDataSeries1((prevData) => [...prevData, newPoint1]);
            setDataSeries2((prevData) => [...prevData, newPoint2]);
            setXCategories((prevCategories) => [
              ...prevCategories,
              newTimestamp,
            ]);

            if (chartRef.current) {
              chartRef.current.chart.series[0].setData(
                [...dataSeries1, newPoint1],
                true
              );
              chartRef.current.chart.series[1].setData(
                [...dataSeries2, newPoint2],
                true
              );
              chartRef.current.chart.xAxis[0].setCategories([
                ...xCategories,
                newTimestamp,
              ]);
            }
          }
        }
      } else {
        setDataSeries1([]);
        setDataSeries2([]);
        setXCategories([]);

        if (chartRef.current) {
          chartRef.current.chart.series[0].setData([], true);
          chartRef.current.chart.series[1].setData([], true);
          chartRef.current.chart.xAxis[0].setCategories([]);
        }

        const series1Data = combineChartData[0]?.map((item) =>
          parseFloat(item.tagValue || 0)
        );
        const series2Data = combineChartData[1]?.map((item) =>
          parseFloat(item.tagValue || 0)
        );
        const timestamps = combineChartData[0]?.map(
          (item) => item?.tagTime || ""
        );

        setDataSeries1(series1Data);
        setDataSeries2(series2Data);
        setXCategories(timestamps);

        if (chartRef.current) {
          chartRef.current.chart.series[0].setData(series1Data, true);
          chartRef.current.chart.series[1].setData(series2Data, true);
          chartRef.current.chart.xAxis[0].setCategories(timestamps);
        }
      }
    }, [
      selectTimeRange,
      sliderRealTimeTagData,
      RealTimeData,
      RealTimeData1,
      combineChartData,
    ]);

    const options = {
      chart: {
        type: "spline",
      },
      legend: {
        enabled: false,
      },
      title: {
        text: null,
      },
      scrollbar: {
        enabled: true,
      },
      exporting: {
        enabled: true,
      },
      xAxis: {
        categories: xCategories,
      },
      yAxis: [
        {
          title: { text: realTimeData[0]?.tag_name || "" },
        },
        {
          title: { text: realTimeData[1]?.tag_name || "" },
          opposite: true,
        },
      ],
      series: [
        {
          name: RealTimeData?.device_tag,
          data: dataSeries1,
          color: "#9A64B2",
          yAxis: 0,
        },
        {
          name: RealTimeData1?.device_tag,
          data: dataSeries2,
          color: "#E579A0",
          yAxis: 1,
        },
      ],
      credits: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        formatter: function () {
          let s = `${this.x}<br/>`;
          this.points.forEach((point) => {
            s += `<br/><span style="color:${point.series.color}">\u25CF</span> ${point.series.name}: <b>${point.y}</b>`;
          });
          return s;
        },
      },
    };

    return (
      <>
        {dataSeries1?.length || dataSeries2?.length ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartRef}
          />
        ) : (
          <img
            src={images.noDataFoundImg}
            className="notFoundDataImg"
            alt="No DataFound Img"
            style={{ paddingTop: "100px",transform: "translate(-50px, -74px)" }}
          />
        )}
      </>
    );
  }
);
