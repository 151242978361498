import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import exporting from "highcharts/modules/exporting";
import { useParams } from "react-router-dom";
import { images } from "../../../config";

HC_more(Highcharts);
exporting(Highcharts);

export const InverterBarChart = React.memo(
  ({ inverterData, showInverterAverage,startDate,endDate }) => {
    const chartRef = useRef(null);
    const param = useParams();

    // console.log("inverterData", inverterData);

    // Prepare data and labels using graph_data
    const graphData = inverterData?.graph_data || [];

    const labels = graphData.map((item) => item.device_name); // X-axis labels from MIN_KEY
    const currentData = graphData.map((item) =>
      parseFloat(item.current_tag_value)
    );
    const pastData = graphData.map((item) => parseFloat(item.past_tag_value));

    const averageValues =
      graphData?.map((item) => item.average_tag_value) || [];

    const dataSeries = [
      {
        name: "Current Tag Value",
        data: currentData,
        color: "#9A64B2",
        stack: "Stack 0",
        barPercentage: 1,
        barThickness: 30,
      },
      {
        name: "Past Tag Value",
        data: pastData,
        color: "#D2D5DB",
        stack: "Stack 1",
        barPercentage: 1,
        barThickness: 30,
      },
    ];

    const options = {
      chart: {
        type: "column",
        events: {
          load: function () {
            const xAxis = this.xAxis[0];
            const visibleCategories = labels.slice(-5);
            xAxis.setExtremes(
              labels.length - visibleCategories.length,
              labels.length - 1,
              false
            );
            this.redraw();
          },
        },
      },
      title: {
        text: null,
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        categories: labels,
      },
      yAxis: {
        stackLabels: {
          enabled: false,
          style: {
            fontWeight: "bold",
            color: "gray",
          },
        },
      },
      plotOptions: {
        series: {
          stacking: "normal",
          dataLabels: {
            enabled: false,
          },
        },
      },
      series: [
        ...dataSeries,
        ...(showInverterAverage
          ? [
              {
                name: "Average Value",
                data: averageValues,
                type: "line",
                dashStyle: "Dash",
                color: "green",
                marker: {
                  enabled: false,
                },
              },
            ]
          : []),
      ],
      scrollbar: {
        enabled: true,
      },
      exporting: {
        enabled: true,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          const pointIndex = this.point.index;
          const pastDataPoint = pastData[pointIndex];
          const currentDataPoint = currentData[pointIndex];
          const averageData1 = graphData[pointIndex];
      
          let tooltipContent = `<b>${labels[pointIndex]}</b><br/><span style="color:#9A64B2">\u25CF</span> Current Value: ${currentDataPoint}`;
          

          
      
          // If startDate and endDate are selected and match, don't show past data
          if (!(startDate && endDate)) {
            // Only show past data if it's outside the selected date range or no range is selected
            tooltipContent += `<br/><span style="color:#D2D5DB">\u25CF</span> Past Value: ${pastDataPoint}`;
          }
      
          if (showInverterAverage && averageData1.average_tag_value) {
            tooltipContent += `<br/><span style="color:green">\u25CF</span> Average Value: ${averageData1.average_tag_value}`;
          }
      
          return tooltipContent;
        },
      },
      
     
      
      // tooltip: {
      //   useHTML: true,
      //   formatter: function () {
      //     const pointIndex = this.point.index;
      //     const pastDataPoint = pastData[pointIndex];
      //     const currentDataPoint = currentData[pointIndex];
      //     const averageData1 = graphData[pointIndex];

      //     let tooltipContent = `<b>${currentDataPoint?.MIN_KEY}</b><br/><span style="color:#9A64B2">\u25CF</span> Current Value: ${currentDataPoint}`;

      //     if (!(startDate && endDate)) {
      //       // Only show past value if startDate and endDate are NOT selected
      //       if (pastDataPoint) {
      //         tooltipContent += `<br/> <span style="color:#D2D5DB">\u25CF</span> Past Value: ${pastDataPoint}`;
      //       }
      //     }

      //     // Check if average data should be shown
      //     if (showInverterAverage && averageData1.average_value) {
      //       tooltipContent += `<br/>Average Value: ${averageData1.average_value}`;
      //     }

      //     return tooltipContent;
      //   },
      // },
    };

    useEffect(() => {
      const chart = chartRef.current?.chart;
      if (chart) {
        chart.options.xAxis.min = labels.length - 5;
        chart.options.xAxis.max = labels.length - 1;
        chart.redraw();
      }
    }, [inverterData]);

    return (
      <>
        {graphData.length > 0 ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartRef}
          />
        ) : (
          <img
            src={images.noDataFoundImg}
            className="notFoundDataImg"
            alt="No Data Found Img"
            style={{ transform: "translate(-50px, 85px)" }}
          />
        )}
      </>
    );
  }
);

// export function InverterBarChart({ inverterData }) {
//   const chartRef = useRef(null);
//   const param = useParams();

//   // Prepare data and labels using graph_data
//   const graphData = inverterData?.graph_data || [];

//   const labels = graphData.map((item) => item.device_name); // X-axis labels from MIN_KEY
//   const currentData = graphData.map((item) =>
//     parseFloat(item.current_tag_value)
//   );
//   const pastData = graphData.map((item) => parseFloat(item.past_tag_value));

//   const dataSeries = [
//     {
//       name: "Current Tag Value",
//       data: currentData,
//       color: "#9A64B2",
//       stack: "Stack 0",
//       barPercentage: 1,
//       barThickness: 30,
//     },
//     {
//       name: "Past Tag Value",
//       data: pastData,
//       color: "#D2D5DB",
//       stack: "Stack 1",
//       barPercentage: 1,
//       barThickness: 30,
//     },
//   ];

//   const options = {
//     chart: {
//       type: "column",
//       events: {
//         load: function () {
//           const xAxis = this.xAxis[0];
//           const visibleCategories = labels.slice(-5);
//           xAxis.setExtremes(
//             labels.length - visibleCategories.length,
//             labels.length - 1,
//             false
//           );
//           this.redraw();
//         },
//       },
//     },
//     title: {
//       text: null,
//     },
//     legend: {
//       enabled: false,
//     },
//     xAxis: {
//       categories: labels,
//     },
//     yAxis: {
//       stackLabels: {
//         enabled: false,
//         style: {
//           fontWeight: "bold",
//           color: "gray",
//         },
//       },
//     },
//     plotOptions: {
//       series: {
//         stacking: "normal",
//         dataLabels: {
//           enabled: false,
//         },
//       },
//     },
//     series: dataSeries,
//     scrollbar: {
//       enabled: true,
//     },
//     exporting: {
//       enabled: true,
//     },
//     credits: {
//       enabled: false,
//     },
//     tooltip: {
//       useHTML: true,
//       formatter: function () {
//         const pointIndex = this.point.index;
//         const pastDataPoint = pastData[pointIndex];
//         const currentDataPoint = currentData[pointIndex];
//         return `<b>${labels[pointIndex]}</b><br/>Current Value: ${currentDataPoint}<br/>Past Value: ${pastDataPoint}`;
//       },
//     },
//   };

//   useEffect(() => {
//     const chart = chartRef.current?.chart;
//     if (chart) {
//       chart.options.xAxis.min = labels.length - 5;
//       chart.options.xAxis.max = labels.length - 1;
//       chart.redraw();
//     }
//   }, [inverterData]);

//   return (
//     <>
//       {graphData.length > 0 ? (
//         <HighchartsReact
//           highcharts={Highcharts}
//           options={options}
//           ref={chartRef}
//         />
//       ) : (
//         <img
//           src={images.noDataFoundImg}
//           className="notFoundDataImg"
//           alt="No Data Found Img"
//         />
//       )}
//     </>
//   );
// }
